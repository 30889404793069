<template>
 <div class="platform" >
   <div class="right" >
     <div class="centre" >
       <h1 class="title" >穿戴设备数据平台</h1>
     </div>
   </div>
   <div class="centre" >
     <div class="pic" >
       <img src="@/assets/Group808.png">
     </div>
   </div>
 </div>
</template>

<script>
export default {
  name: "platform.vue"
}
</script>

<style scoped>


  .centre {
    font-family: 'PingFang SC';
    width: 1246px;
    margin: 0 auto;
  }

  .right {
    background-color: #f5f6f8;
  }

  .right .centre {
    display: flex;
    justify-content: flex-end;
  }


  .centre .title {
    margin-right: 80px;
    height: 178px;
    font-weight: 600;
    font-size: 32px;
    line-height: 178px;
  }

.centre .pic {
  padding-top: 80px;
  line-height: 0;
}

  .centre .pic img {
    width: 1221px;
  }



</style>
