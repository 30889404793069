<template>
  <div class="apparel" v-if="viewport">
    <banner :navIndex="1">
      <template slot="banner">
        <div class="banner">
          <div class="centre" :style="{'will-change': 'transform',transform: `translate3d(0,${transLateY}px,0)`}">
            <h1 class="title">智能穿戴设备</h1>
            <div class="line"></div>
            <div class="text">
              <p>
                智能穿戴设备将多通道生物传感器技术、生物大数据以及人工智能算法相结合，实现对各类不同模式生理信号的长时间、高精度连续监测，建立属于用户的个性化健康模型，通过智能算法感知用户的健康状态，分析疾病风险，并给予相应健康建议，帮助用户和专业医生实现疾病的早发现、早诊断、早治疗。同时设备还可用于帮助重症病人进行生理指标监测、辅助医生进行慢性疾病进展评估，以及对急症病人病因的快速诊断等领域。
              </p>
            </div>
          </div>
        </div>
      </template>
    </banner>
    <div style="position: relative;z-index: 2;background-color: #fff;  min-width: 1600px;">
      <Slideshow ref="slideshow"></Slideshow>
      <platform></platform>
      <div class="footer-box">
        <relation></relation>
      </div>
    </div>
  </div>
  <div v-else class="phone-wrap">
    <div class="background">
      <div class="nav">
        <div class="logo">
          <img src="@/assets/phone/phone-logo.png">
        </div>
        <div @click="onList" class="fork">
          <span class="iconfont icon-liebiaoshitucaidan"></span>
        </div>
      </div>
      <div class="title">
        <h1>智能穿戴设备</h1>
      </div>

      <div v-show="list" class="list">
        <phoneNav @onHide="onHide"></phoneNav>
      </div>
    </div>
    <div class="text">
      <p>
        智能穿戴设备将多通道生物传感器技术、生物大数据以及人工智能算法相结合，实现对各类不同模式生理信号的长时间、高精度连续监测，建立属于用户的个性化健康模型，通过智能算法感知用户的健康状态，分析疾病风险，并给予相应健康建议，帮助用户和专业医生实现疾病的早发现、早诊断、早治疗。同时设备还可用于帮助重症病人进行生理指标监测、辅助医生进行慢性疾病进展评估，以及对急症病人病因的快速诊断等领域。
      </p>
    </div>
    <div class="tab" >
      <div class="tab-nav">
          <div :class="{active : item === 1}" @click="onTab(1)" >基础穿戴</div>
          <div :class="{active : item === 2}" @click="onTab(2)" >多元穿戴</div>
          <div :class="{active : item === 3}" @click="onTab(3)" >专项穿戴</div>
      </div>
      <div class="detail" >
        <div v-if="item === 1" class="unify basics" >
        <div class="order" >
          <img src="@/assets/phone/01.png">
        </div>
          <div class="introduce">
            <h3>基础穿戴</h3>
            <p>
              基于PPG与ECG穿戴技术<br />
              结合AI算法实现长周期的健康监测与健康管理
            </p>
          </div>
          <div class="picture" >
            <div class="group">
              <img src="@/assets/phone/Group791.png">
            </div>
            <div class="sling" >
              <img src="@/assets/phone/sling.png">
            </div>
          </div>
          <div class="exercise" >
            <div class="line-chart" >
              <img src="@/assets/phone/Group792.png">
            </div>
            <div class="watches" >
              <img src="@/assets/phone/slide_4.png">
            </div>
          </div>
        </div>
        <div v-if="item === 2" class="unify polybasic">
          <div class="order" >
            <img src="@/assets/phone/02.png">
          </div>
          <div class="introduce">
            <h3>多元穿戴</h3>
            <p>
              综合运用多种传感技术<br />
              实现多层面的人体数据采集与监测管理
            </p>
          </div>
          <div class="picture" >
            <img src="@/assets/phone/Group794.png">
          </div>
        </div>
        <div v-if="item === 3" class="unify special" >
          <div class="order" >
            <img src="@/assets/phone/03.png">
          </div>
          <div class="introduce">
            <h3>专项穿戴</h3>
            <p>
              面部情绪监测<br />
              人体体态监测
            </p>
          </div>
          <div class="picture">
          <div class="person" >
            <img src="@/assets/phone/Group795.png">
          </div>
            <div class="vertical" >
              <div class="skeleton">
                <img src="@/assets/phone/Maskgroup10.png">
              </div>
              <div class="expression" >
                <img src="@/assets/phone/Group796.png">
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="platform" >
      <div class="title">
        <h2>穿戴设备数据平台</h2>
      </div>
      <div class="human-body">
        <div class="perception" >
        <div class="signal">
          <img src="@/assets/phone/signalstrength.png">
        </div>
          <h4>穿戴信号感知</h4>
        </div>
        <div class="pic" >
          <img src="@/assets/phone/Maskgroup.png">
        </div>
      </div>
      <div class="information" >
      <div class="communication" >
        <div class="phone" >
          <img src="@/assets/phone/iphone1.png">
        </div>
        <h5>数据通信</h5>
        <div class="wireless">
          <img src="@/assets/phone/wifi.png">
        </div>
      </div>
        <div class="text">
          <div>数据传输</div>
          <div>5G通信</div>
        </div>
        <div class="icon" >
          <div class="left-line" ></div>
          <div class="iconfont">
            <img src="@/assets/phone/Vector.png">
          </div>
          <div class="right-line" ></div>
        </div>
        <div class="task" >数据任务</div>
        <div class="exercise">
          <div>健康数据</div>
          <div class="line" >运动数据</div>
          <div>状态数据</div>
        </div>
        <div class="arrows" >
          <img src="@/assets/phone/Vector.png">
        </div>
      </div>
      <div class="information" >
        <div class="communication top" >
          <div class="phone" >
            <img src="@/assets/phone/uploadone.png">
          </div>
          <h5>数据平台</h5>
          <div class="wireless">
            <img src="@/assets/phone/code-computer.png">
          </div>
        </div>
        <div class="text2">
          <div>数据存储平台</div>
          <div>数据计算平台</div>
        </div>
        <div class="icon" >
          <div class="left-line" ></div>
          <div class="iconfont" >
            <img src="@/assets/phone/Vector.png">
          </div>
          <div class="right-line" ></div>
        </div>
        <div class="task" >智能计算</div>
        <div class="calculate exercise">
          <div>数据处理平台</div>
          <div class="line" >模型训练平台</div>
          <div>AI计算平台</div>
        </div>
        <div class="arrows" >
          <img src="@/assets/phone/Vector.png">
        </div>
        <div class="AIalgorithm">AI算法</div>
        <div class="algorithm exercise">
          <div>慢病监测AI算法</div>
          <div class="line" >重症监测AI算法</div>
          <div>运动监测AI算法</div>
        </div>
        <div class="arrows" >
          <img src="@/assets/phone/Vector.png">
        </div>
        <div class="AIalgorithm" >健康管理</div>
        <div class="manage exercise">
          <div>风险预警提示</div>
          <div class="line" >体检方案生成</div>
          <div>诊疗方案推荐</div>
        </div>
      </div>
    </div>
    <phoneFooter></phoneFooter>
  </div>
</template>

<script>
import banner from '@/components/banner'
import Slideshow from './components/slideshow'
import platform from './components/platform'
import relation from '@/components/footer'
import phoneNav from '@/components/phoneNav/index'
import phoneFooter from "@/components/phoneFooter";


export default {
  name: "apparel.vue",

  components: {
    phoneNav,
    banner,
    Slideshow,
    platform,
    relation,
    phoneFooter,

  },
  data() {
    return {
      toTop: 0,
      transLateY: 0,
      scroll: false,
      viewport: true,
      screenWidth:null,
      list: false,
      item: 1,
    }
  },
  watch: {
    screenWidth: function (n, o) {
      if (n <= 1024) {
        this.viewport=false
      } else {
        this.viewport=true
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll) //监听页面滚动
    this.screenWidth = document.body.clientWidth
    if (document.documentElement.clientWidth < 1280) {
      this.viewport = false
    }
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }

  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {

    onList() {
      setTimeout(() =>{
        this.list = true
      }, 200)

    },

    onHide() {
      setTimeout(() =>{
        this.list = false
      }, 200)

    },


    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop,
          radio = window.screen.width <= 1600 ? 0.7 : 1
      if (scrollTop < (900 * radio)) {
        if (this.toTop <= scrollTop) {
          this.transLateY = scrollTop / 10
        } else {
          this.transLateY = -scrollTop / 10
        }
      }
      //判断鼠标方向
      this.scroll = this.toTop < scrollTop
      this.toTop = scrollTop
      if (scrollTop > (850 * radio) && scrollTop < (1800 * radio) && this.scroll) {
        this.$refs.slideshow.init(true)
      } else if (scrollTop < (910 * radio) && !this.scroll) {
        this.$refs.slideshow.init(true)
      } else {
        this.$refs.slideshow.init(false)
      }
    },

    onTab(index) {
      this.item = index

    }
  }
}
</script>

<style scoped>
.apparel {
  min-width: 1280px;
}


.banner .centre {
  width: 1200px;
  margin: 0 auto;
}

.centre .title {
  margin-top: 166px;
  color: #FFFFFF;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 54px;
  line-height: 76px;
  letter-spacing: 2px;
}

.centre .line {
  margin-top: 60px;
  margin-bottom: 76px;
  width: 60px;
  height: 6px;
  background-color: #FFFFFF;
}

.centre .text {
  margin-bottom: 225px;
}

.centre .text p {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 50px;
  color: #FFFFFF;
  letter-spacing: 1px;
  opacity: 0.8;
}



  .phone-wrap {
    font-family: 'PingFang SC';
  }

  .nav {
    display: flex;
    justify-content: space-between;
    padding: 1.14rem 0.6rem 0;
  }

  .nav .logo {
    display: flex;
    align-items: center;
    height: 0.43rem;
  }

  .nav .logo img {
    height: 0.35rem;
  }

  .nav .fork {
    color: #FFFFFF;
    line-height: 0.43rem;
    height: 0.43rem;
  }

  .nav .fork .iconfont {
    font-size: 0.36rem;
  }

  .list {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }

  .background {
    background-image: url("../../assets/phone/apparel.jpg");
    background-repeat: no-repeat;
    /*background-size: 100% 100%;*/
    background-size: cover;
  }

  .background .title {
    padding: 1.6rem 0 2.06rem 0.7rem;
  }

  .background .title h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.46rem;
    line-height: 0.64rem;
    color: #FFFFFF;
    letter-spacing: 0.03rem;
  }

  .phone-wrap .text {
    padding: 0.8rem 0.6rem 0.6rem;
  }

  .phone-wrap .text p {
    font-style: normal;
    font-weight: 400;
    font-size: 0.24rem;
    line-height: 0.5rem;
    color: #111111;
    letter-spacing: 0.02rem;
  }

  /*.text .line {*/
  /*  width: 0.6rem;*/
  /*  height: 0.05rem;*/
  /*  background: linear-gradient(to right, #A3DBEE, #CD7482);*/
  /*}*/

  .tab {
    padding: 0 0.6rem;
  }

  .tab .tab-nav {
    display: flex;
    justify-content: space-between;
    padding-top: 0.64rem;
    height: 1.57rem;
    border-bottom: 0.01rem solid #D7D7D7;
  }

  .tab .tab-nav div {
    position: relative;
    padding-bottom: 0.64rem;
    list-style: none;
    font-style: normal;
    font-weight: 400;
    font-size: 0.28rem;
    line-height: 0.72rem;
    color: #999999;
  }

  .tab .tab-nav .active {
    color: #111111;
  }

  .tab .tab-nav .active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.03rem;
    background: linear-gradient(to right, #A3DBEE, #CD7482);

  }

  .tab .unify {
    padding-top: 1rem;
    padding-bottom: 1.6rem;
  }

  .tab .unify .order {
    position: relative;
  }

  .tab .unify .order img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 0.62rem;
  }

  .tab .unify .introduce {
    padding-top: 0.24rem;
  }

  .unify .introduce h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.38rem;
    line-height: 0.73rem;
    color: #111111;
    letter-spacing: 0.02rem;
  }

  .unify .introduce p {
    margin-top: 0.19rem;
    font-style: normal;
    font-weight: 400;
    font-size: 0.24rem;
    line-height: 0.52rem;
    letter-spacing: 0.02rem;
  }

  .basics .picture {
    display: flex;
    padding-top: 0.3rem;
  }

  .basics .picture .group {
    width: 3.6rem;
  }

  .basics .picture .group img {
    width: 3.6rem;
  }

  .basics .picture .sling img {
    width: 64%;
  }

  .basics .picture .sling {
    margin-top: 0.48rem;
    margin-left: 0.2rem;
    padding-top: 0.29rem;
    width: 2.5rem;
    height: 2.2rem;
    background: #F0F5F9;
    border-radius: 8px;
    text-align: center;
  }

  .basics .exercise {
    margin-top: 0.2rem;
    display: flex;
  }

  .basics .exercise .line-chart img {
    width: 3.6rem;
  }

  .basics .exercise .watches {
    margin-left: 0.2rem;
    padding-top: 0.11rem;
    width: 2.5rem;
    height: 1.8rem;
    background: #F0F5F9;
    border-radius: 0.08rem;
    text-align: center;
  }

  .basics .exercise .watches img {
    height: 1.6rem;
  }

  .polybasic .picture {
    margin-top: 0.3rem;
    height: 4.75rem;
  }

  .polybasic .picture img {
    width: 6.3rem;
  }


  .special .picture {
    display: flex;
    margin-top: 0.78rem;
  }

  .special .picture .person img {
    width: 3.25rem;
  }

  .special .picture .skeleton img {
    width: 2.85rem;
  }

  .special .picture .expression {
    margin-top: 0.12rem;
  }

  .special .picture .expression img {
    width: 2.85rem;
  }

  .special .picture .vertical {
    margin-left: 0.2rem;
  }

  .platform {
    padding-bottom: 2rem;
  }

  .platform .title {
    padding: 0.48rem 0 0.48rem 0.6rem;
    background: #F5F6F8;
  }

  .platform .title h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.38rem;
    line-height: 0.4rem;
    color: #111111;
    letter-spacing: 0.03rem;
  }

  .platform .human-body {
    padding-top: 1rem;
  }

  .platform .human-body .perception {
    display: flex;
    justify-content: center;
  }

  .human-body .perception .signal {
    padding-top: 0.02rem;
  }

  .human-body .perception .signal img {
    height: 0.3rem;
  }

  .platform .human-body .perception h4 {
    margin-left: 0.17rem;
    font-style: normal;
    font-weight: 500;
    font-size: 0.28rem;
    line-height: 0.39rem;
    letter-spacing: 0.02rem;
  }

  .platform .human-body .pic {
    padding-bottom: 0.75rem;
    margin-top: 0.6rem;
    text-align: center;
  }

  .platform .human-body .pic img {
    width: 6.34rem;
  }


  .platform .communication {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
  }

  .platform .communication .phone {
    width: 1rem;
    height: 1rem;
    background: #2D2E33;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .platform .communication .phone img {
    width: 0.66rem;
  }

  .platform .communication h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 0.28rem;
    line-height: 0.39rem;
    letter-spacing: 0.02rem;
  }

  .platform .communication .wireless {
    width: 1rem;
    height: 1rem;
    background: #2D2E33;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .platform .communication .wireless img {
    width: 0.66rem;
  }

  .platform .top {
    margin-top: 0.82rem;
  }

  .information .text {
    margin-top: 0.2rem;
    display: flex;
    justify-content: space-between;
    padding: 0 1.5rem;
  }


  .information .text div {
    width: 1rem;
    font-style: normal;
    font-weight: 400;
    font-size: 0.24rem;
    line-height: 0.34rem;
    color: #111111;
    text-align: center;
  }

  .information .text2 {
    display: flex;
    justify-content: space-between;
    margin-top: 0.2rem;
    padding: 0 1.28rem;
    font-style: normal;
    font-weight: 400;
    font-size: 0.24rem;
    line-height: 0.34rem;
    color: #111111;
    text-align: center;
  }



  .information .icon {
    margin-top: 0.3rem;
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;

  }

  .information .icon .left-line {
    width: 0.4rem;
    height: 0;
    border: 0.01rem dashed #7AABCC;
    transform: rotate(45deg);
  }

  .information .icon .right-line {
    width: 0.4rem;
    height: 0;
    border: 0.01rem dashed #7AABCC;
    transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
  }

  .information .icon .iconfont {
    line-height: 0;
  }

  .information .icon .iconfont img {
    height: 0.24rem;
  }

  .information .task {
    margin-top: 0.82rem;
    font-style: normal;
    font-weight: 500;
    font-size: 0.28rem;
    line-height: 0.39rem;
    text-align: center;
  }

  .information .AIalgorithm {
    margin-top: 0.52rem;
    font-style: normal;
    font-weight: 500;
    font-size: 0.28rem;
    line-height: 0.39rem;
    text-align: center;
  }

  .information .exercise {
    margin-top: 0.4rem;
    padding: 0 1.71rem;
    display: flex;
    justify-content: space-between;
  }

  .information .exercise div {
    font-style: normal;
    font-weight: 400;
    font-size: 0.24rem;
    line-height: 0.34rem;
    color: #111111;
  }

  .information .exercise .line {
    padding: 0 0.3rem;
    border-left: 0.01rem dashed #7AABCC;
    border-right: 0.01rem dashed #7AABCC;
  }

  .information .arrows {
    margin-top: 0.52rem;
    text-align: center;
    line-height: 0;
  }

  .information .arrows img {
    height: 0.24rem;
  }

  .information .calculate {
    padding-left: 0.93rem;
    padding-right: 1.1rem;
    display: flex;
    justify-content: space-between;
  }

  .information .algorithm {
    padding: 0 0.62rem ;
    display: flex;
    justify-content: space-between;
  }

  .information .manage {
    padding: 0 0.93rem;
  }
</style>
