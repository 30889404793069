<template>
  <div class="slideshow">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="slide-l">
            <div class="box">
              <div class="sequence">
                <img alt="" src="@/assets/01.png">
              </div>
              <h2>基础穿戴</h2>
              <p>基于PPG与ECG穿戴技术结合AI算法实现长周期的健康监测与健康管理</p>
            </div>
            <div class="progress">
              <div class="progress-l" style="width: 120px"></div>
              <div class="progress-r"></div>
            </div>
          </div>
          <div class="slide-r">
            <div class="box_1">
              <div class="box-l">
                <div class="pic">
                  <img alt="" src="@/assets/slide_1.png">
                </div>
                <div class="pic">
                  <img alt="" src="@/assets/slide_2.png">
                </div>
              </div>
              <div class="box-r">
                <div class="pic">
                  <img alt="" src="@/assets/slide_3.png">
                </div>
                <div class="pic">
                  <img alt="" src="@/assets/slide_4.png">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="slide-l">
            <div class="box">
              <div class="sequence">
                <img alt="" src="@/assets/02.png">
              </div>
              <h2>多元穿戴</h2>
              <p>综合运用多种传感技术实现多层面的人体数据采集与监测管理</p>
            </div>
            <div class="progress">
              <div class="progress-l" style="width: 240px"></div>
              <div class="progress-r"></div>
            </div>
          </div>
          <div class="slide-r">
            <div class="box_2">
              <div class="pic">
                <img alt="" src="@/assets/slide_5.png">
              </div>
            </div>
          </div>
        </div>
          <div class="swiper-slide">
              <div class="slide-l">
                <div class="box">
                  <div class="sequence">
                    <img alt="" src="@/assets/03.png">
                  </div>
                  <h2>专项穿戴</h2>
                  <p>面部情绪监测<br/>人体体态监测</p>
                </div>
                <div class="progress">
                  <div class="progress-l" style="width: 360px"></div>
                  <div class="progress-r"></div>
                </div>
              </div>
              <div class="slide-r">
                <div class="box_3">
                  <div class="box-l">
                    <div class="pic">
                      <img alt="" src="@/assets/slide_7.png">
                    </div>
                  </div>
                  <div class="box-r">
                    <div class="pic">
                      <img alt="" src="@/assets/slide_8.png">
                    </div>
                    <div class="label-list">
                      <div class="ul">
                        <div class="li">愤怒</div>
                        <div class="li">厌恶</div>
                        <div class="li">恐惧</div>
                      </div>
                      <div class="ul">
                        <div class="li">惊讶</div>
                        <div class="li">快乐</div>
                        <div class="li">悲伤</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

  import Swiper from "swiper";

  import "swiper/dist/js/swiper";
  import "swiper/dist/css/swiper.css";
  let swiper;
  export default {
    props: {},
    data() {
      return {
        oldIndex:1,
        index: 1,
      };
    },

    components: {},
    mounted() {
      swiper = new Swiper(".swiper-container", {
        direction: "horizontal",
        loop: false,
        mousewheel: {
          releaseOnEdges: true,
        },
      });
      swiper.mousewheel.disable()
    },
    methods: {
      init(flag){
        if(flag){
          swiper.mousewheel.enable()
        }else{
          swiper.mousewheel.disable()
        }

      }
    },
  };
</script>


<style scoped>
  .slideshow {
    background-color: #fff;
    min-width: 1600px;
    height: 900px;
  }
  .swiper-container {
    width: 100%;
    height: 900px;
  }
  .swiper-wrapper {
    width: 100%;
    height: 900px;
  }
  .swiper-slide {
    width: 100%;
    height: 900px;
    box-sizing:border-box;
    padding: 0 0 75px 18.75vw;
    /*width: 81.25vw;*/
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .slide-l{
    width: 375px;
    margin-right: 125px;
  }
  .slide-l .box .sequence {
    line-height: 0;
    width: 118px;
    height: 80px;
  }
  .slide-l .box .sequence img {
    width: 100%;
    height: 100%;
  }
  .slide-l .box h2 {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 90px;
    color: #111111;
    margin-top: -40px;
    margin-bottom: 40px;
  }
  .slide-l .box p {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 70px;
    color: #111111;
    min-height: 340px;
  }
  .slide-r{
    flex: 1;
  }
  .slide-r .box_1{
    width: 100%;
    height: 100%;
    display: flex;
    align-items:flex-end;
  }
  .slide-r .box_1 .box-l{
    width: 616px;
  }
  .slide-r .box_1 .box-l .pic{
    width: 100%;
    line-height: 0;
    margin-top: 30px;
  }
  .slide-r .box_1 .box-l .pic:nth-child(1) img{
    width:100%;
    height: 421px;
  }
  .slide-r .box_1 .box-l .pic:nth-child(2) img{
    width:100%;
    height: 304px;
  }
  .slide-r .box_1 .box-r{
    flex: 1;
    margin-left: 30px;
  }
  .slide-r .box_1 .box-r .pic{
    width: 100%;
    line-height: 0;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F0F3F5;
    border-radius: 12px;
  }
  .slide-r .box_1 .box-r .pic:nth-child(1){
    height: 356px;
  }
  .slide-r .box_1 .box-r .pic:nth-child(2){
    height: 304px;
  }
  .slide-r .box_1 .box-r .pic:nth-child(1) img{
    width: 266px;
    height: 260px;
  }
  .slide-r .box_1 .box-r .pic:nth-child(2) img{
    width: 146.81px;
    height: 243px;
  }
  .slide-r .box_2{
    width: 100%;
  }
  .slide-r .box_2 .pic{
    width: 100%;
    height: 665px;
    background: #F0F3F5;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .slide-r .box_2 .pic img{
    width: 1060px;
    height: 756px;
  }
  .slide-r .box_3{
    width: 100%;
  }
  .slide-r .box_3 .box-l .pic{
    line-height: 0;
    width: 560px;
    height: 697px;
    background: #F0F3F5;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .slide-r .box_3{
    display: flex;
    align-items: flex-end;
  }
  .slide-r .box_3 .box-l .pic img{
    width: 100%;
    height: 100%;
  }
  .slide-r .box_3 .box-r{
    flex: 1;
    margin-left: 30px;
  }
  .slide-r .box_3 .box-r .pic{
    width: 100%;
    line-height: 0;
    border-radius: 18px;
    display: flex;
    align-items: center;
  }
  .slide-r .box_3 .box-r .pic img{
    width: 470px;
    height: 412px;
  }
  .slide-r .box_3 .box-r .label-list{
    width: 470px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 255px;
    background: #F0F3F5;
    border-radius: 18px 0 0 18px;
    margin-top: 30px;
  }
  .slide-r .box_3 .box-r .label-list .ul{
    width: 470px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-style: normal;
    box-sizing:border-box;
    padding: 0 48px;
  }
  .slide-r .box_3 .box-r .label-list .ul:nth-child(1){
    margin-bottom: 38px;
  }
  .slide-r .box_3 .box-r .label-list .ul .li{
    width: 99px;
    height: 60px;
    background: rgba(159, 197, 223, 0.2);
    border: 1px solid #FFFFFF;
    border-radius: 14px;
    font-family: 'PingFang SC';
    font-weight: 500;
    font-size: 26px;
    line-height: 60px;
    text-align: center;
    color: #111111;
  }
  .progress {
    width: 360px;
    height: 2px;
    display: flex;
    align-items: center;
  }

  .progress .progress-l {
    background-color: #000;
    height: 2px;
    transition: 1s;
  }

  .progress .progress-r {
    flex: 1;
    height: 2px;
    background: linear-gradient(to right, #A3DBEE, #CD7482);
  }


</style>
